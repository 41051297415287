var exports = {};
var nums = {
  0: "\u0966",
  1: "\u0967",
  2: "\u0968",
  3: "\u0969",
  4: "\u096A",
  5: "\u096B",
  6: "\u096C",
  7: "\u096D",
  8: "\u096E",
  9: "\u096F"
};

exports = function (strNum) {
  var arrNumNe = strNum.toString().split("").map(function (ch) {
    if (ch === "." || ch === ",") {
      return ch;
    }

    return nums[Number(ch)];
  });
  return arrNumNe.join("");
};

export default exports;